//Cobro File
import createDataContext from "./createDataContext";
import * as sharedActions from "./sharedActions";
import * as mainActions from "./mainActions";
// import * as cobroActions from "./cobroActions";
import { localStorageKey, localStorageAuth, localStorageCurrentRoute, mainApp, localStorageSync } from "@/config";
import produce from "immer";
import { getObjectByIndex } from "./Selectors";
import { addWeeks } from "date-fns";
import jwtDecode from "jwt-decode";
import { printDate, calcNewLoanStatus, calcNewCompleted, newDate, getTimestamp } from "@shared/functions";
// import logger from "../logService";

let initialState = null;

if (mainApp === 0) {
  initialState = {
    syncronizations: [],
    queueLogs: [],
    client: {},
    showSyncProgress: null,
    lastFetch: {},
    route: { creditor_id: 0 },
    loans: [],
    gastos: [],
    debtors: [],
    securirityQuestions: [],
    signatures: [],
    collected: [],
    newLoans: [],
    referrals: {},
    debtorImages: [],
    newDebtors: [],
    userConfig: {},
    routeConfig: {},
    user: { user_id: 0, jwt: false },
    jwt: false,
    permissions: false,
    misc: {
      dispatchLogs: [],
      gastos: 0,
      collected: 0,
      collectedCount: 0,
      newLoansCount: 0,
      paymentMora: 0,
      entregas: 0,
      adelanto: 0,
      actanotarial: 0,
    },
    snackbar: { open: false, type: "success", duration: 5000, message: "" },
    device: { bgSyncQueueSize: 0, username: "" },
  };
} else {
  initialState = {
    client: {},
    lastFetch: {},
    signatures: [],
    accessCodes: [],
    cobroList: {},
    cobroListChanges: {},
    route: { creditor_id: 0 },
    loans: [],
    cuadre: {},
    securirityQuestions: [],
    paidLoans: [],
    employees: [],
    employeeRoutes: [],
    gastos: [],
    collected: [],
    newLoans: [],
    restritec_users: [2995, 3034],
    debtorImages: [],
    debtors: [],
    referrals: {},
    userTemporary: { user_id: 0 },
    user: { user_id: 0, jwt: false },
    misc: { deviceInfo: {}, PWA: null, id: 0 },
    snackbar: { open: false, type: "success", duration: 5000, message: "" },
    device: { darkMode: false, username: "" },
  };
}

//device property should be preserved on login it should never be deleted.
const dataReducer = (state, action) => {
  let newData;
  let index = -1;
  let index2 = -1;
  let index3 = -1;
  let c = {};
  switch (action.type) {
    case "pushSync":
      newData = produce(state, (draft) => {
        draft.syncronizations.push({ ...action.payload });
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "dispatchLogs":
      newData = produce(state, (draft) => {
        draft.misc.dispatchLogs.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "printCuadreData":
      newData = produce(state, (draft) => {
        draft.misc.cuadre = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchLoanPayments":
      index = state.loans.findIndex((loan) => loan.money_id === action.money_id);

      newData = produce(state, (draft) => {
        draft.loans[index].paymentsLastFetch = { timestamp: Date.now() / 1000, id: action.money_id };
        draft.loans[index].payments = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "unsetSync":
      newData = produce(state, (draft) => {
        draft.showSyncProgress = null;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "updateSyncSuccess":
      index = state.syncronizations.findIndex((x) => x.syncronization_id === action.payload.syncronization_id);
      newData = produce(state, (draft) => {
        draft.syncronizations[index].sync = true;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setBgSyncQueueSize":
      newData = produce(state, (draft) => {
        draft.device.bgSyncQueueSize = action.payload.bgSyncQueueSize;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "pushQueueResult":
      newData = produce(state, (draft) => {
        draft.queueLogs.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "increaseBgSyncQueueSize":
      const queues = getLocalData(localStorageSync) || [];
      newData = produce(state, (draft) => {
        draft.device.bgSyncQueueSize = queues.length;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "fetchAccessCodes":
      newData = produce(state, (draft) => {
        draft.accessCodes = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCollected":
      newData = produce(state, (draft) => {
        draft.lastFetch.collected = {
          timestamp: Date.now() / 1000,
          id: action.creditor_id + action.dates.start_date + action.dates.end_date,
        };
        draft.collected = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchNewLoans":
      newData = produce(state, (draft) => {
        draft.lastFetch.newLoans = {
          timestamp: Date.now() / 1000,
          id: action.creditor_id + action.dates.start_date + action.dates.end_date,
        };
        draft.newLoans = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "changeRoute":
      newData = { ...state, route: action.payload };
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updatelastCheckUpdate":
      newData = produce(state, (draft) => {
        draft.route.lastCheckUpdate = action.payload.lastCheckUpdate;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "remeberUsername":
      newData = produce(state, (draft) => {
        draft.device = { ...draft.device, ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setDeviceInfo":
      newData = produce(state, (draft) => {
        draft.device = { ...state.device, ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setReferral":
      newData = produce(state, (draft) => {
        draft.invite = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "increaseIncorrectAccess":
      newData = produce(state, (draft) => {
        draft.incorrectAccess = action.payload.incorrectAccess;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setSnackbar":
      newData = { ...state, snackbar: { ...state.snackbar, ...action.payload } };
      // storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setCollectedSync":
      index = state.collected.findIndex((x) => x.payment_id === action.payload.payment_id);
      // index2 = state.loans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.collected[index] = { ...state.collected[index], ...action.payload };
        // draft.loans[index2] = { ...state.loans[index2], modified_time: action.payload.modified_time };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setGastoSync":
      index = state.gastos.findIndex((x) => x.id === action.payload.id);
      newData = produce(state, (draft) => {
        draft.gastos[index] = { ...state.gastos[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setNewLoansSync":
      index = state.newLoans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.newLoans[index] = { ...state.newLoans[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "unLockCuadre":
      newData = produce(state, (draft) => {
        draft.permissions = { ...state.permissions, perm_cuadre: true };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setNewDebtorSync":
      index = state.debtors.findIndex((x) => x.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors[index] = { ...state.debtors[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setLoanSyncRequired":
      index = state.loans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans[index] = { ...state.loans[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "createRoute":
      newData = produce(state, (draft) => {
        draft.routes.push(action.payload);
      });

      // storeLocalData(localStorageKey, newData, true);
      return newData;

    case "modifyRoute":
      newData = produce(state, (draft) => {
        draft.route = { ...state.route, ...action.payload };
      });

      // storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setLinkedRoutes":
      newData = produce(state, (draft) => {
        draft.routes = action.payload;
      });

      // storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateUserConfig":
      newData = produce(state, (draft) => {
        draft.userConfig = { ...state.userConfig, ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateRouteConfig":
      newData = produce(state, (draft) => {
        draft.routeConfig = { ...state.routeConfig, ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateRouteData":
      newData = produce(state, (draft) => {
        draft.route = {
          ...state.route,
          route_name: action.payload.route_name,
          location: action.payload.location,
          rPerc: action.payload.rPerc,
          rWeeks: action.payload.rWeeks,
          phone: action.payload.phone,
          phone2: action.payload.phone2,
          user_id: action.payload.user_id,
        };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroCreatePayment":
      const { ...x } = action.payload;
      index = state.loans.findIndex((loan) => loan.money_id === x.money_id);
      c.loan = getObjectByIndex(state.loans, index);

      c.newLoanStatus = calcNewLoanStatus(c.loan, x.paymentAmount);
      c.newCompleted = calcNewCompleted(c.loan, x.paymentAmount);
      c.newNextDate = printDate(addWeeks(newDate(c.loan.given_date), c.newCompleted + 1), "yyyy-MM-dd");

      c.paymentAmount = c.loan.paymentAmount + x.paymentAmount;
      c.paymentMora = c.loan.paymentMora + x.paymentMora;

      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.loans[index] = {
          ...draft.loans[index],
          ...x,
          timestamp: getTimestamp(),
          last_date: printDate(new Date(), "Y-MM-dd"),
          completed: c.newCompleted,
          postPoned: null,
          next_date: c.newNextDate,
          paymentAmount: c.paymentAmount,
          paymentMora: c.paymentMora,
          balance: c.loan.balance - x.paymentAmount,
          xCobrarAmountTotal: c.loan.xCobrarAmountTotal - x.paymentAmount,
          mora: c.loan.mora - x.paymentMora,
          statusAmount: c.newLoanStatus.statusAmount,
          statusText: c.newLoanStatus.statusText,
        };

        draft.collected.unshift({
          ...x,
          name: c.loan.name,
          timestamp: getTimestamp(),
          paymentMora: c.loan.paymentMora + x.paymentMora,
          paymentAmount: c.loan.paymentAmount + x.paymentAmount,
          paymentMoraDisplay: x.paymentMora,
          paymentAmountDisplay: x.paymentAmount,
          start_date: c.loan.start_date,
          debtor_id: c.loan.debtor_id,
          npayments: c.loan.npayments,
          wPayment: c.loan.wPayment,
          pending: c.loan.pending,
          incomplete: c.loan.incomplete,
          amount: c.loan.amount,
          given_date: c.loan.given_date,
          completed: c.newCompleted,
          balance: c.loan.balance - x.paymentAmount,
          mora: c.loan.mora - x.paymentMora,
          // statusAmount: c.newLoanStatus.statusAmount,
          // statusText: c.newLoanStatus.statusText,
          time: printDate(new Date(), "dd-MMM-yy - hh:mm:ss aaa"),
        });

        draft.misc.collectedCount++;
        if (x.paymentType !== "deposit") {
          draft.misc.collected += x.paymentAmount;
          draft.misc.paymentMora += x.paymentMora;
        }

        //Move loan to the last position
        draft.loans.push(draft.loans.splice(index, 1)[0]);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroDeletePayment":
      const { ...z } = action.payload;

      index = state.collected.findIndex((payment) => payment.payment_id === z.payment_id);
      index2 = state.loans.findIndex((loan) => loan.money_id === z.money_id);

      c.payment = getObjectByIndex(state.collected, index);
      c.loan = getObjectByIndex(state.loans, index2);

      c.paymentAmount = c.loan.paymentAmount - c.payment.paymentAmountDisplay;
      c.paymentMora = c.loan.paymentMora - c.payment.paymentMoraDisplay;
      c.balance = c.loan.balance + c.payment.paymentAmountDisplay;
      c.mora = c.loan.mora + c.payment.paymentMoraDisplay;
      c.xCobrarAmountTotal = c.loan.xCobrarAmountTotal + c.payment.paymentAmountDisplay;

      c.newLoanStatus = calcNewLoanStatus(c.loan, 0 - c.payment.paymentAmountDisplay);
      c.newCompleted = calcNewCompleted(c.loan, 0 - c.payment.paymentAmountDisplay);

      c.newNextDate = printDate(addWeeks(newDate(c.loan.given_date), c.newCompleted + 1), "yyyy-MM-dd");

      newData = produce(state, (draft) => {
        //Update payoffBalance property on newLoan object
        if (z.payoffLoanId) {
          index3 = state.newLoans.findIndex((y) => !y.deleted && y.money_id === z.payoffLoanId);
          if (index3 !== -1) {
            draft.newLoans[index3].payoffBalance -= c.payment.paymentAmountDisplay;
          }
        }

        // draft.showSyncProgress = Date.now();
        // draft.collected.splice(index, 1);
        draft.collected[index].deleted = true;
        draft.loans[index2] = {
          ...c.loan,
          paymentAmount: c.paymentAmount,
          paymentMora: c.paymentMora,
          paidFromRenewal: false,
          completed: c.paymentAmount === 0 ? c.loan.static.completed : c.newCompleted,
          next_date: c.paymentAmount === 0 ? c.loan.static.next_date : c.newNextDate,
          mora: c.mora,
          balance: c.balance,
          xCobrarAmountTotal: c.xCobrarAmountTotal,
          last_date: c.paymentAmount === 0 ? c.loan.static.last_date : c.loan.last_date,
          statusAmount: c.paymentAmount === 0 ? c.loan.static.statusAmount : c.newLoanStatus.statusAmount,
          statusText: c.paymentAmount === 0 ? c.loan.static.statusText : c.newLoanStatus.statusText,
        };

        draft.misc.collectedCount--;
        if (c.payment.paymentType !== "deposit") {
          draft.misc.collected -= c.payment.paymentAmountDisplay;
          draft.misc.paymentMora -= c.payment.paymentMoraDisplay;
        }
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroModifyNewLoan":
      index = state.newLoans.findIndex((loan) => loan.money_id === action.payload.money_id);
      let { amount, npayments, percentage, wPayment, adelanto, actanotarial } = action.payload;
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.newLoans[index].percentage = percentage;
        draft.newLoans[index].amount = amount;
        draft.newLoans[index].npayments = npayments;
        draft.newLoans[index].adelanto = adelanto;
        draft.newLoans[index].wPayment = wPayment;
        draft.newLoans[index].actanotarial = actanotarial;
        // draft.newLoans[index].synced = false;

        draft.misc.entregas += amount - state.newLoans[index].amount;
        draft.misc.adelanto += adelanto - state.newLoans[index].adelanto;
        draft.misc.actanotarial += actanotarial - state.newLoans[index].actanotarial;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "awaitActionCode":
      newData = produce(state, (draft) => {
        draft.awaitActionCode = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "deleteNewLoan":
      index = state.newLoans.findIndex((newLoan) => newLoan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.newLoans[index].deleted = true;
        draft.misc.entregas -= state.newLoans[index].amount;
        draft.misc.adelanto -= state.newLoans[index].adelanto;
        draft.misc.actanotarial -= state.newLoans[index].actanotarial;
        draft.misc.newLoansCount--;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateLoanToggle":
      index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans[index].isHidden = !state.loans[index].isHidden;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateLoanDelta":
      index = state.loans.findIndex((loan) => loan.mUniqueId === action.payload.mUniqueId);
      newData = produce(state, (draft) => {
        draft.loans[index] = { ...state.loans[index], ...action.payload.loan };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    // case "updateLoanDeletedPaymentDelta":
    //   index = state.loans.findIndex((loan) => loan.mUniqueId === action.payload.mUniqueId);
    //   newData = produce(state, (draft) => {
    //     draft.loans[index] = { ...action.payload.loan, ...state.loans[index] };
    //   });

    //   storeLocalData(localStorageKey, newData, true);
    //   return newData;
    case "insertLoanDelta":
      newData = produce(state, (draft) => {
        draft.loans.unshift(action.payload.loan);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "getDebtorLoans":
      const { loans, debtor_id } = action.payload;
      newData = produce(state, (draft) => {
        draft.loans = [...loans.filter((debtor) => debtor.debtor_id !== debtor_id), ...loans];
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "createGasto":
      newData = produce(state, (draft) => {
        draft.gastos.unshift(action.payload);
        draft.misc.gastos += action.payload.amount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "getDebtorRenewal":
      index = state.debtors.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors[index] = { ...draft.debtors[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "cobroCreateGasto":
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.gastos.unshift(action.payload);
        draft.misc.gastos += action.payload.amount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "fetchGastos":
      newData = produce(state, (draft) => {
        draft.lastFetch.gastos = {
          timestamp: Date.now() / 1000,
          id: action.creditor_id + action.dates.start_date + action.dates.end_date,
        };
        draft.gastos = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "toggleLinkedRoute":
      index = state.employees.findIndex((user) => user.user_id === action.payload.user_id);
      newData = produce(state, (draft) => {
        draft.employees[index].is_active = action.payload.is_active === "1" ? "0" : "1";
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "createLoan":
      newData = produce(state, (draft) => {
        draft.loans.unshift(action.payload);
        draft.newLoans.unshift(action.payload);
        draft.temporaryLoanData = null;
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "createPayment":
      index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans[index] = { ...draft.loans[index], ...action.payload };

        draft.collected.unshift(action.payload);

        //Move loan to the last position
        draft.loans.push(draft.loans.splice(index, 1)[0]);
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "setDebtorsReordered":
      newData = produce(state, (draft) => {
        draft.misc.debtorsReorded = true;
        draft.misc.updatedDebtorsReorded = true;
        draft.debtorsReorded = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroDeleteGasto":
      index = state.gastos.findIndex((x) => x.id === action.payload.id);

      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.gastos[index].deleted = true;
        draft.misc.gastos -= state.gastos[index].amount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "deleteGasto":
      index = state.gastos.findIndex((x) => x.id === action.payload.id);
      newData = produce(state, (draft) => {
        draft.gastos.splice(index, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "deleteBanksSyncronization":
      newData = produce(state, (draft) => {
        draft.syncronizations = state.syncronizations.filter((x) => x.type !== "bankUpdate");
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "loanDelete":
      index = state.loans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans.splice(index, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "deletePayment":
      //TODO: When deleted loan should retunrned info and update current Loan
      index = state.collected.findIndex((x) => x.payment_id === action.payload.payment_id);
      newData = produce(state, (draft) => {
        draft.collected.splice(index, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "debtorDelete":
      index = state.debtors.findIndex((x) => x.debtor_id === action.payload.debtor_id);
      index2 = state.loans.findIndex((x) => x.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors.splice(index, 1);
        draft.loans.splice(index2, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroUpdateBankAmount":
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.route.bank_amount = action.payload.bankAmount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateBankAmount":
      newData = produce(state, (draft) => {
        draft.cuadre.bank_amount = action.payload.bankAmount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateEfectivoAmount":
      newData = produce(state, (draft) => {
        draft.cuadre.actualCash = action.payload.actualCash;
        draft.cuadre.note = action.payload.note;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "createNewLoan":
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.newLoans.unshift({ ...action.payload, timestamp: Date.now() });
        draft.misc.entregas += action.payload.amount;
        draft.misc.adelanto += action.payload.adelanto;
        draft.misc.actanotarial += action.payload.actanotarial;
        draft.temporaryLoanData = null;
        draft.misc.newLoansCount++;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchDebtors":
      newData = produce(state, (draft) => {
        draft.lastFetch.debtors = { timestamp: Date.now() / 1000, id: action.creditor_id };
        draft.debtors = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchDebtor":
      newData = produce(state, (draft) => {
        draft.debtors.unshift(action.payload);
        draft.debtorsReorded.unshift({ debtor_id: action.payload.debtor_id, name: action.payload.name });
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    // case "updateSyncLoan":
    //   const { ...s } = action.payload;
    //   index = state.loans.findIndex((loan) => loan.money_id === s.money_id);

    //   newData = produce(state, (draft) => {
    //     if (index !== -1) {
    //       c.loan = getObjectByIndex(state.loans, index);

    //       c.newLoanStatus = calcNewLoanStatus(s, c.loan.paymentAmount);
    //       c.newCompleted = calcNewCompleted(s, c.loan.paymentAmount);
    //       c.newNextDate = printDate(addWeeks(newDate(s.given_date), c.newCompleted + 1), "yyyy-MM-dd");

    //       draft.loans[index] = {
    //         ...action.payload,
    //         timestamp: c.loan.timestamp,
    //         isHidden: c.loan.isHidden,
    //         paymentAmount: s.balance >= c.loan.paymentAmount ? c.loan.paymentAmount : s.balance,
    //         paymentMora: s.mora >= c.loan.paymentMora ? c.loan.paymentMora : s.mora,
    //         balance: s.balance >= c.loan.paymentAmount ? s.balance - c.loan.paymentAmount : 0,
    //         mora: s.mora >= c.loan.paymentMora ? s.mora - c.loan.paymentMora : 0,
    //         additionalPayment: c.loan.additionalPayment,
    //         additionalMora: c.loan.additionalMora,
    //         last_date: c.loan.paymentAmount > 0 ? c.loan.last_date : s.last_date,
    //         completed: c.newCompleted,
    //         next_date: c.newNextDate,
    //         xCobrarAmountTotal: s.xCobrarAmountTotal - c.loan.paymentAmount,
    //         statusAmount: c.newLoanStatus.statusAmount,
    //         statusText: c.newLoanStatus.statusText,
    //         payoffLoanId: c.loan.payoffLoanId,
    //         paidFromRenewal: c.loan.paidFromRenewal,
    //       };
    //     } else {
    //       index2 = state.debtors.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
    //       draft.loans.unshift(action.payload);

    //       //Insert to debtors array if loan belongs to a brand new Debtor.
    //       if (index2 === -1) {
    //         // draft.debtors.unshift(action.payload);
    //       }
    //     }
    //   });

    //   storeLocalData(localStorageKey, newData, true);
    //   return newData;
    // case "deleteSyncLoan":
    //   index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
    //   if (index === -1) return;

    //   newData = produce(state, (draft) => {
    //     draft.loans.splice(index, 1);
    //     //TODO: Umm? this like looks bad
    //     draft.misc.collected -= state.loans[index].paymentAmount;
    //     draft.misc.paymentMora -= state.loans[index].paymentMora;
    //     if (state.loans[index].paymentAmount > 0) {
    //       draft.misc.collectedCount--;
    //     }
    //   });

    //   storeLocalData(localStorageKey, newData, true);
    //   return newData;
    case "fetchLoans":
      newData = produce(state, (draft) => {
        draft.lastFetch.loans = { timestamp: Date.now() / 1000, id: action.creditor_id };
        draft.loans = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCobroList":
      newData = produce(state, (draft) => {
        draft.lastFetch.cobroList = { timestamp: Date.now() / 1000, id: action.payload.cuadre_id };
        draft.cobroList = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCobroListChanges":
      newData = produce(state, (draft) => {
        draft.lastFetch.cobroListChanges = { timestamp: Date.now() / 1000, id: action.payload.cuadre_id };
        draft.cobroListChanges = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCuadre":
      newData = produce(state, (draft) => {
        draft.lastFetch.cuadre = { timestamp: Date.now() / 1000, id: action.payload.cuadre_id };
        draft.cuadre = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setCustomerDisabled":
      newData = produce(state, (draft) => {
        draft.customerDisabled = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroFetchLoans":
      newData = produce(state, (draft) => {
        draft.lastFetch.loans = { timestamp: Date.now() / 1000, id: action.creditor_id };
        draft.loans = action.payload.loans;
        draft.debtorsReorded = action.payload.debtorsReorded;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updatePostPone":
      newData = produce(state, (draft) => {
        draft.loans = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroSignin":
      newData = produce(state, (draft) => {
        draft.permissions = action.payload.permissions;
        draft.jwt = action.payload.jwt;
        draft.user = action.payload.user;
        draft.route = action.payload.route;
        draft.userConfig = action.payload.userConfig;
        draft.routeConfig = action.payload.routeConfig;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setAuthUser":
      newData = produce(state, (draft) => {
        draft.user = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setAuthUserTemporary":
      newData = produce(state, (draft) => {
        draft.userTemporary = action.payload;
      });

      return newData;
    case "updateLoansOrder":
      newData = produce(state, (draft) => {
        draft.loans = action.payload;
        draft.misc.updatedDebtorsReorded = false;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroCreateDebtor":
      newData = produce(state, (draft) => {
        // draft.debtors.unshift({ ...action.payload, debtorSearch: action.payload.name });
        draft.newDebtors.push({ ...action.payload, debtorSearch: action.payload.name });
        draft.debtorsReorded.unshift({
          debtor_id: action.payload.dUniqueId,
          name: action.payload.name,
          new_debtor: true,
        });
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "createDebtor":
      newData = produce(state, (draft) => {
        draft.debtors.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateDebtor":
      index = state.debtors.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors[index] = { ...draft.debtors[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "createEmployee":
      newData = produce(state, (draft) => {
        draft.employees.push(action.payload);
      });

      storeLocalData(localStorageKey, newData);
      return newData;

    case "cobroUpdateDebtor":
      index = state.debtors.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
      index2 = state.debtorsReorded.findIndex((debtor) => debtor.debtor_id === action.payload.dUniqueId);
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        if (index > -1) {
          draft.debtors[index] = { ...draft.debtors[index], ...action.payload };
        }

        if (index2 > -1) {
          draft.debtorsReorded[index2].name = action.payload.name;
        }
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchLoan":
      //TODO: This will create a duplicate Loan?
      newData = produce(state, (draft) => {
        draft.loans.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "toggleDarkMode":
      newData = produce(state, (draft) => {
        draft.device.darkMode = !state.device.darkMode;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setUserRole":
      index = state.employees.findIndex((user) => user.user_id === action.payload.user.user_id);
      newData = produce(state, (draft) => {
        draft.employees[index] = { ...draft.employees[index], ...action.payload.role, is_active: "1" };
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "updateDebtorLoan":
      index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        if (index > -1) {
          draft.loans[index].name = action.payload.name;
          draft.loans[index].phone = action.payload.phone;
        }
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setPermissions":
      newData = produce(state, (draft) => {
        draft.permissions = action.payload.permissions;
        draft.roles = action.payload.roles;
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "fetchEmployees":
      newData = produce(state, (draft) => {
        draft.employees = action.payload;
        draft.lastFetch.employees = { timestamp: Date.now() / 1000, id: action.creditor_id };
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "fetchEmployeesLinkedRoutes":
      newData = produce(state, (draft) => {
        draft.employeeRoutes = action.payload;
        draft.lastFetch.employeeRoutes = { timestamp: Date.now() / 1000, id: action.user_id };
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "fetchRouteInfo":
      newData = produce(state, (draft) => {
        draft.route = action.payload.route;
        draft.user = action.payload.user;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchRouteData":
      newData = produce(state, (draft) => {
        draft.cuadre = { ...action.payload };
        draft.lastFetch.route = { timestamp: Date.now() / 1000, id: action.creditor_id };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "saveSecurityQuestions":
      newData = produce(state, (draft) => {
        draft.user.questionSet = "1";
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "restoreAppState":
      newData = produce(state, (draft) => {
        draft.user = action.payload.user;
        draft.route = action.payload.route;
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "restoreCobroAppState":
      newData = { ...initialState, ...action.payload };
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "logout":
      //When Loging out, this dispatch will keep whatever was stored in device object.
      newData = { ...initialState, device: { ...state.device } };
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "cobroLogout":
      //When Loging out, this dispatch will keep whatever was stored in device object.
      newData = produce(state, (draft) => {
        draft.jwt = null;
        draft.user = { user_id: 0 };
        draft.route = { creditor_id: 0 };
        draft.lastFetch = {};
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "getReferrals":
      newData = produce(state, (draft) => {
        draft.lastFetch.referrals = { timestamp: Date.now() / 1000, id: action.user_id };
        draft.referrals = action.payload;
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "createReferralLink":
      newData = produce(state, (draft) => {
        draft.referrals.invitations.unshift(action.payload);
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "passwordResetLookup":
      newData = { ...state, passwordReset: { ...action.payload } };
      return newData;
    case "changeUserVerified":
      newData = produce(state, (draft) => {
        draft.user.verified = action.payload.verified;
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "getSecurityQuestions":
      newData = produce(state, (draft) => {
        draft.securirityQuestions = action.payload;
        draft.lastFetch.securirityQuestions = { timestamp: Date.now() / 1000, id: action.id };
      });
      storeLocalData(localStorageKey, newData);
      return newData;
    case "changePersonalInfo":
      newData = { ...state, user: { ...state.user, ...action.payload } };
      storeLocalData(localStorageKey, newData);
      return newData;
    case "changeUsername":
      newData = { ...state, user: { ...state.user, username: action.payload } };
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setClientDetail":
      newData = { ...state, client: { ...state.client, ...action.payload } };
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "temporaryLoanData":
      newData = produce(state, (draft) => {
        draft.temporaryLoanData = action.payload;
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setLoanIdentification":
      newData = produce(state, (draft) => {
        draft.temporaryLoanData = { ...state.temporaryLoanData, dataImage: action.payload.data };
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "phoneVerification":
      newData = { ...state, passwordNew: { ...action.payload } };
      return newData;
    case "appendDebtorImage":
      newData = produce(state, (draft) => {
        draft.debtorImages.push(action.payload);
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchLoanSignature":
      newData = produce(state, (draft) => {
        draft.signatures.push(action.payload);
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "getUserRoutesBalance":
      newData = produce(state, (draft) => {
        draft.userRoutesBalance = action.payload;
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;

    default:
      if (import.meta.env.DEV) {
        throw new Error("No Reducer found!");
      } else {
        return state;
      }
  }
};

const logout = (dispatch) => {
  return (callback) => {
    console.log("AppWorkflow - logout from dataContext - Deleted all Local Contents");
    dispatch({ type: "logout" });

    localStorage.removeItem(localStorageAuth);

    if (callback) callback();
  };
};

const setDeviceInfo = (dispatch) => {
  return async (data) => {
    try {
      dispatch({ type: "setDeviceInfo", payload: data });
    } catch (err) {
      console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const restoreAppState = (dispatch) => {
  return (history) => {
    try {
      const userJwt = getLocalData(localStorageAuth);
      const route = getLocalData(localStorageCurrentRoute);

      if (userJwt) {
        const decodedJwt = jwtDecode(userJwt);

        var current_time = Date.now() / 1000;
        if (decodedJwt.exp < current_time) {
          console.log("AppWorkflow - Auth Token is expired Logout user here.js");
          history.push("/login");
          return;
        }

        if (!route) {
          history.push("/routes/choose");
          return;
        }

        dispatch({
          type: "restoreAppState",
          payload: { user: decodedJwt.data, route },
        });
      } else {
        console.log("AppWorkflow - Auth Token Not found Logout user here.js", localStorageAuth);
        history.push("/login");
      }
      // console.log("AppWorkflow - restoreAppState from dataContext - Restored State");
    } catch (e) {}
  };
};

const getJwtToken = (dispatch) => {
  //This method should only checkk if the token is present at at Local Storage.
  return () => {
    try {
      const data = getLocalData(localStorageAuth);
      if (data) {
        const decodedJwt = jwtDecode(data);

        var current_time = Date.now() / 1000;
        if (decodedJwt.exp < current_time) {
          console.log("AppWorkflow - Auth Token is expired Logout user here.js");
          dispatch({ type: "logout" });
          console.log("AppWorkflow - getJwtToken from dataContext - Invalid Token");
          return null;
        }

        return decodedJwt.data;
      } else {
        dispatch({ type: "logout" });
        console.log("AppWorkflow - getJwtToken from dataContext - Invalid Token");
        return null;
      }
    } catch (err) {
      dispatch({ type: "logout" });
      console.log("AppWorkflow - getJwtToken from dataContext - catch Error");
      console.log(err);
      return null;
    }
  };
};

const storeLocalData = (key, value, allowInProduction = false) => {
  if (import.meta.env.DEV || allowInProduction === true) {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (err) {
      console.log(err);
    }
  }
};

const getLocalData = (key) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (err) {
    console.log("AppWorkflow - getLocalData from dataContext - catch Error");
    console.log(err);
  }
};

export const { Context, Provider } = createDataContext(
  dataReducer,
  {
    ...sharedActions,
    ...mainActions,
    restoreAppState,
    logout,
    getJwtToken,
    setDeviceInfo,
  },
  initialState
);
